import React from "react"

/*
 
    Default breakpoints came from https://www.npmjs.com/package/react-only#default-breakpoints
 
    Size From To
    xs	0px	575px
    sm	576px	767px
    md	768px	991px
    lg	992px	1199px
    xl	1200px	-
*/

export const breakpoints = {
  xs: `(max-width: 575px)`,
  sm: `(min-width: 576px) and (max-width: 767px)`,
  md: `(min-width: 768px) and (max-width: 991px)`,
  lg: `(min-width: 992px) and (max-width: 1439px)`,
  xl: `(min-width: 1440px)`,
}

export const space = <>&nbsp;</>
