import styled, { css } from "styled-components"
import { breakpoints } from "../middleware/utils"

const Text = styled.span`
  ${({
    textAlign,
    color = "#ffffff",
    textDecoration,
    fontStyle = "normal",
    display = "block",
    fontWeight = "normal",
    sstextAlign,
    ssFontWeight,
    letterSpacing,
    fontFamily = "Arial",
    fontSize = "18px",
    margin = "0",
    padding = "0",
    textTransform,
    lineHeight,
    width,
    opacity,
    ssOpacity,
    ssFontSize,
    ssLineHeight,
    ssLetterSpacing,
    ssMargin,
    ssPadding,
    ssFontStyle,
    textStroke,
  }) => css`
    ${textAlign && `text-align: ${textAlign};`}
    color: ${color};
    margin: ${margin};
    padding: ${padding};
    font-weight: ${fontWeight};
    font-family: ${fontFamily};
    font-size: ${fontSize};
    font-style: ${fontStyle};
    width: ${width || "100%"};
    ${textStroke && `-webkit-text-stroke: ${textStroke}`};
    ${lineHeight && `line-height: ${lineHeight}`};
    ${display && `display: ${display}`};
    ${opacity && `opacity: ${opacity}`};
    ${letterSpacing && `letter-spacing: ${letterSpacing}`};
    ${textDecoration && `text-decoration: ${textDecoration}`};
    ${textTransform && `text-transform: ${textTransform}`};
    
    @media ${breakpoints.xs}, ${breakpoints.sm}, ${breakpoints.md} {
      ${ssMargin && `margin: ${ssMargin}`};
      ${ssPadding && `padding: ${ssPadding}`};
      ${ssOpacity && `opacity: ${ssOpacity}`};
      ${ssLineHeight && `line-height: ${ssLineHeight}`};
      ${ssFontSize && `font-size: ${ssFontSize}`};
      ${ssFontStyle && `font-style: ${ssFontStyle}`};
      ${ssLetterSpacing && `letter-spacing: ${ssLetterSpacing}`};
    }
  `}
`

export default Text
