import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import { breakpoints } from "../../middleware/utils"
import Logo from "../../images/logo.svg"
import Text from "../../shared_components/Text"

const Container = styled.div`
  height: 100px;
  width: 100%;
  margin: 0;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  background: transparent;
  max-width: 1440px;
  z-index: 2;

  @media ${breakpoints.xs}, ${breakpoints.sm}, ${breakpoints.md} {
    padding-left: 16px;
    height: 80px;
  }

  > div {
    align-self: center;

    :hover {
      cursor: pointer;
    }
  }

  .toggle-button {
    align-self: center;
    display: none;

    &.desktop {
      display: block;
      margin-right: 50px;
      margin-bottom: 10px;
    }

    @media ${breakpoints.xs}, ${breakpoints.sm}, ${breakpoints.md} {
      display: block;
    }
  }

  img,
  svg {
    height: 73px;
    width: 160px;

    @media ${breakpoints.xs}, ${breakpoints.sm}, ${breakpoints.md} {
      width: 115px;
      height: 53px;
      margin-top: 5px;
    }
  }
`

const HiContainerInfo = styled.div`
  .text {
    color: ${({ theme }) => theme.infoText};
    height: 60px;
  }

  width: 221px;
`

const HiContainerText = styled.div`
  display: flex;
  margin-top: 16px;

  @media ${breakpoints.xs}, ${breakpoints.sm}, ${breakpoints.md} {
    display: none;
    margin-top: 0px;
  }
`

export default function Header({ toggleButton }) {
  return (
    <Container as="header">
      <div
        aria-label="Navigate to Home page"
        onClick={() => navigate("/")}
        onKeyDown={() => navigate("/")}
        role="button"
        tabIndex={0}
      >
        <Logo />
      </div>

      <div className="toggle-button">{toggleButton && toggleButton()}</div>

      <HiContainerText>
        <div className="toggle-button desktop">
          {toggleButton && toggleButton()}
        </div>
        <HiContainerInfo className="dark-mode">
          <Text
            className="text"
            fontFamily="CircularStd-Book"
            fontSize="16px"
            lineHeight="24px"
            textAlign="left"
          >
            Reading time indicator <br />
            1min 35 sec (396 words)
          </Text>
        </HiContainerInfo>
      </HiContainerText>
    </Container>
  )
}
