import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import { breakpoints } from "../../middleware/utils"

import Text from "../../shared_components/Text"

const Container = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;

  @media ${breakpoints.xs}, ${breakpoints.sm}, ${breakpoints.md} {
    padding: 0 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: auto;

    .first {
      order: 3;
      margin-bottom: 24px;
    }
  }

  .text {
    color: ${({ theme }) => theme.infoText};
    margin-right: 90px;
    white-space: nowrap;

    @media ${breakpoints.xs}, ${breakpoints.sm}, ${breakpoints.md} {
      margin: 24px 0 0 0;
    }
  }

  .text-container {
    display: flex;

    span {
      :hover {
        cursor: pointer;
      }
    }

    @media ${breakpoints.xs}, ${breakpoints.sm}, ${breakpoints.md} {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
`

export default function Footer() {
  return (
    <Container>
      <div className="first">
        <Text
          className="text"
          fontFamily="Georgia"
          fontSize="16px"
          lineHeight="16px"
          ssLineHeight="24px"
          letterSpacing="-0.04em"
        >
          © {new Date().getFullYear()}, Founders Network Limited
        </Text>
      </div>
      <div className="text-container">
        <Text
          className="text second"
          fontFamily="Georgia"
          fontSize="16px"
          lineHeight="16px"
          ssLineHeight="24px"
          letterSpacing="-0.04em"
          onClick={() => navigate("/terms")}
        >
          Terms of Service
        </Text>
        <Text
          className="text last-child"
          fontFamily="Georgia"
          fontSize="16px"
          lineHeight="16px"
          ssLineHeight="24px"
          letterSpacing="-0.04em"
          onClick={() => navigate("/privacy")}
        >
          Privacy Policy
        </Text>
      </div>
    </Container>
  )
}
