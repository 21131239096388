export const lightTheme = {
  body: "#F9F8F5",
  icons: "#000000",
  text: "#363537",
  borderBg: "#000000",
  gradient: "linear-gradient(#39598A, #79D7ED)",
  heading: "#000000",
  toggleCircle: "#C5FF00",
  togglePosition: "flex-end",
  infoText: "#31343E",
  infoTextAlt: "#FFFFFF",
  theThingIs: "#2A2A2A",
  rollerCoasterBg: "#FFFFFF",
  superPowerBg: "#FFFFFF",
  hustler: "#121355",
  MobileHustler: " #121355",
  hustlerBackground: "#F9F8F5",
  BuildingProductBackground: "#F9F8F5",
  fuckBg: "#000000",
  hiImg: "#FFFFFF",
  hiTextColor: "#FFFFFF",
}

export const darkTheme = {
  infoTextAlt: "#31343E",
  icons: "#FFFFFF",
  body: "#000000",
  borderBg: "#FFFFFF",
  text: "#FAFAFA",
  toggleBorder: "#FFFFFF",
  toggleCircle: "#000000",
  togglePosition: "flex-end",
  gradient: "linear-gradient(#091236, #1E215D)",
  heading: "#C5FF00",
  infoText: "#FFFFFF",
  theThingIs: "#F4FFFE",
  rollerCoasterBg: "#000000",
  superPowerBg: "#000000",
  hustler: "#F0FCCD",
  MobileHustler: "#C5FF00",
  hustlerBackground: "#080808",
  BuildingProductBackground: "#080808",
  fuckBg: "#F9F8F5",
  hiImg: "#1E1F22",
  hiTextColor: "#1E1F22",
}
