// Toggle.js
import React from "react"
import styled from "styled-components"

const ToggleContainer = styled.span`
  background: ${({ theme }) => theme.borderBg};
  border: none;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  font-size: 0.5rem;
  justify-content: ${({ themeMode }) =>
    themeMode === "light" ? "flex-start" : "flex-end"};
  margin: 0 auto;
  padding: 4px 5px;
  position: relative;
  width: 64px;
  height: 32px;
  transition: all 0.3s linear;
  outline: none;
`

const Circle = styled.div`
  transition: all 0.3s linear;
  background: ${({ theme }) => theme.toggleCircle};
  border-radius: 50%;
  height: 24px;
  width: 24px;
`

export default function Toggle({ theme, toggleTheme }) {
  return (
    <ToggleContainer onClick={toggleTheme} themeMode={theme}>
      <Circle />
    </ToggleContainer>
  )
}
