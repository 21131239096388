import { useEffect, useState } from "react"

export const useDarkMode = () => {
  const [theme, setTheme] = useState("light")
  const [componentMounted, setComponentMounted] = useState(false)
  const setMode = mode => {
    window.localStorage.setItem("theme", mode)
    setTheme(mode)
  }


  const setClassOnDocument = (mode) => {
    const darkMode = mode === "dark";
    const classNameDark = 'dark-mode';
    const classNameLight = 'light-mode';
    document.documentElement.classList.add(darkMode ? classNameDark : classNameLight);
    document.documentElement.classList.remove(darkMode ? classNameLight : classNameDark);
  }

  const toggleTheme = () => {
    if (theme === "light") {
      setMode("dark")
      setClassOnDocument("dark")
    } else {
      setMode("light")
      setClassOnDocument("light")
    }
  }

  useEffect(() => {
    const defaultTheme = "dark"
    const localTheme = window.localStorage.getItem("theme")

    if (localTheme) {
      setTheme(localTheme)
      setMode(localTheme)
      setClassOnDocument(localTheme)
    } else {
        setTheme(defaultTheme)
        setMode(defaultTheme)
        setClassOnDocument(defaultTheme)
    }

    setComponentMounted(true)
  }, [])

  return [theme, toggleTheme, componentMounted]
}
