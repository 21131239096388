import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { GlobalHotKeys } from "react-hotkeys"

import { breakpoints } from "../middleware/utils"
import { useDarkMode } from "../hooks/useDarkMode"
import { lightTheme, darkTheme } from "../middleware/theme"
import Toggle from "../shared_components/Toggle"
import { GlobalStyles } from "../global"
// import Seo from "./Seo"

import Header from "./sections/Header"
import Footer from "./sections/Footer"

import "../app.css"

const Wrapper = styled.div`
  width: 100vw;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2.5rem;

  @media ${breakpoints.xs}, ${breakpoints.sm}, ${breakpoints.md} {
    overflow: hidden;
    padding: 0;
  }
`

export default function Layout({ children }) {
  const [theme, toggleTheme, componentMounted] = useDarkMode()
  const themeMode = theme === "light" ? lightTheme : darkTheme

  if (!componentMounted) {
    return <div />
  }

  const toggleButton = () => <Toggle theme={theme} toggleTheme={toggleTheme} />

  const handlers = {
    TOGGLE_THEME: () => {
      toggleTheme()
    },
  }

  const keyMap = {
    TOGGLE_THEME: ["command+shift+L", "ctrl+shift+L"],
  }

  return (
    <ThemeProvider theme={themeMode}>
      <>
        {/* <Seo /> */}
        <GlobalStyles />
        <GlobalHotKeys allowChanges handlers={handlers} keyMap={keyMap}>
          <Wrapper>
            <Header toggleButton={toggleButton} />
            {children}
            <Footer />
          </Wrapper>
        </GlobalHotKeys>
      </>
    </ThemeProvider>
  )
}
